<template>
    <div
        class="container-main"
        :class="
            type_active === 'remember' || type_active === 'login-token' || type_active === 'login-ba'
                ? 'large-one'
                : 'large-two'
        "
    >
        <template v-if="type_active !== 'not-have-permission'">
            <div class="content-box content-login" :class="{ 'border-0': config.hide_register_btn }"
                 v-if="type_active === 'login' || type_active === 'register'">
                <span class="title">{{ $t('ct-login') }}</span>
                <login @change="changeType"></login>

                <div>
                    <a href="#" class="text-forgot-password" @click.prevent="changeType('remember')">{{
                            $t('ct-forgot-password')
                        }}</a>
                </div>
            </div>

            <template v-if="!config.hide_register_btn">
                <div class="content-box content-register" v-if="type_active === 'login' || type_active === 'register'">
                    <span class="title">{{ $t('ct-register') }}</span>
                    <register :areas_register="areas_register" @change="changeType"></register>
                </div>
            </template>

            <div v-if="type_active === 'remember'" class="content-box content-forgot forgot">
                <span class="title">{{ $t('ct-forgot-password') }}</span>
                <remember-password @change="changeType"></remember-password>

                <div class="mx-5 mt-2 d-flex">
                    <div class="text-center flex-grow-1">
                        <template v-if="!config.hide_register_btn">
                            <a href="#" class="helper-forgot-password"
                               @click.prevent="changeType('register')">{{ $t('ct-register') }}</a>
                            <span class="mx-1 helper-forgot-password">|</span>
                        </template>
                        <a href="#" class="helper-forgot-password"
                           @click.prevent="changeType('login')">{{ $t('ct-sign-in') }}</a>
                    </div>
                </div>
            </div>

            <div v-if="type_active === 'login-token'" class="content-box content-forgot forgot">
                <span class="title">{{ $t('ct-access-with-token') }}</span>
                <login-token @change="changeType"></login-token>
            </div>

            <div v-if="type_active === 'login-ba'" class="content-box content-forgot forgot">
                <span class="title">{{ $t('ct-access-ba-credentials') }}</span>
                <login-builderall @change="changeType"></login-builderall>
            </div>
        </template>

        <template v-else-if="type_active === 'not-have-permission'">
            <not-have-permission></not-have-permission>
        </template>
    </div>
</template>

<script>
import {mixinRestrictAreaConfig, mixinRestrictAreaForm} from '@/js/mixins/restrictAreaForm';

export default {
    name: 'TwoColumnsPopUp',
    mixins: [mixinRestrictAreaConfig, mixinRestrictAreaForm],
};
</script>

<style lang="scss" scoped>
.title {
    display: block;
    text-align: left;
    font-size: 18px;
    margin-bottom: 15px;
}

.large-two {
    width: auto;
    @media (min-width: 851px) {
        width: 800px;
    }
}

.large-one {
    width: auto;
}
</style>
